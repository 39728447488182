import * as React from "react";
import {connect} from "react-redux";
import {navigate} from "gatsby";

// components
import DefaultLayoutComponent from "../../components/layouts/DefaultLayoutComponent";
import {setConsequences} from "../../redux/actions/global/setConsequences";
import {getResultSkeletonConfig} from "../../config/ResultSkeletonConfig";
import ModalComponent from "../../components/ModalComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";

const DecisionSummaryPage = ({isNewState, consequences, dispatch}) => {
    const [specialEventModalOpenState, setSpecialEventModalOpenState] = React.useState(false);

    React.useEffect(() => {
        if (isNewState) {
            navigate('/');
        }

        if(consequences != null && consequences.specialEvent != null) {
            setSpecialEventModalOpenState(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        if(consequences.endGameConsequences != null && consequences.previousGame === "main") {
            navigate("/fin", {replace: true});
        } else {
            dispatch(setConsequences(getResultSkeletonConfig()));
            navigate("/code", {replace: true});
        }
    };

    let heading = "CONSÉQUENCES";
    let btnLabel = "Continuer";

    if (consequences != null && consequences.endGameConsequences != null && consequences.previousGame !== "main") {
        heading = "DÉBUT DE LA PARTIE";
        btnLabel = "C'est parti !";
    }

    return (
        <DefaultLayoutComponent>
            <h1 className="display-6 stone-light text-center px-3 mb-0">{heading}</h1>
            {consequences != null && consequences.endGameConsequences != null && (consequences.previousGame === "tuto" || consequences.previousGame === "profile") ?
                <div className="ready-wrapper text-center px-3">
                    {consequences.previousGame === "tuto" ? <h2 className="display-4">6/6</h2> : <h2 className="display-4">1/1</h2>}
                    <p className="lead">{consequences.endGameConsequences.message}</p>
                </div>
            :
                <div className="msg-wrapper px-3">
                    {consequences != null && consequences.message != null ? (<div><p className="font-size-2 text-center">{consequences.message}</p><hr/></div>) : false}
                    {consequences != null && consequences.attributes != null && Object.keys(consequences.attributes).length > 0 ? (<p className="font-size-2">Conséquences sur vos attributs: </p>) : consequences != null && consequences.endGameType == null && (<p className="font-size-2 text-center">Aucune conséquences sur vos attributs. </p>)}
                    {consequences != null && consequences.attributes != null && consequences.attributes.loss != null ?
                        consequences.attributes.loss.map((item) => {
                            return <span className="text-danger font-size-2" key={item.attribute}>-{item.value} {item.attribute}<br/></span>;
                        })
                    : false}
                    {consequences != null && consequences.attributes != null && consequences.attributes.gain != null ?
                        consequences.attributes.gain.map((item) => {
                            return <span className="text-success font-size-2" key={item.attribute}>+{item.value} {item.attribute}<br/></span>;
                        })
                    : false}
                </div>
            }
            <div className="text-center">
                <button className="hk-btn hk-btn-light mx-auto mb-1" onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                </button>
                <p className="text-muted mb-0">
                    <small>{btnLabel}</small>
                </p>
            </div>
            <ModalComponent modalId="specialEventModal" modalType="SpecialEvent" open={specialEventModalOpenState}/>
        </DefaultLayoutComponent>
    )
};

export default connect(state => ({
    isNewState: state.global.codeList == null,
    consequences: state.global.consequences
}), null)(DecisionSummaryPage)
